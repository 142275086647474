/** @jsx jsx */
import ExhibitorPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorPage';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import {
  getCertificationsForId,
  extractPartnerIdsForCharacteristics,
} from '@bottlebooks/gatsby-theme-event-pages/src/helpers/brandCertifications';
import getTopDropPricing from '../getTopDropPricing';

export default function ExhibitorTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { previous, next } = data;
  const { site, exhibitor, event } = afterQuery(data);
  return (
    <ExhibitorPage
      {...pageContext}
      event={event}
      exhibitor={exhibitor}
      products={exhibitor && exhibitor.products}
      previous={previous}
      next={next}
      siteTitle={site.siteMetadata.title}
      location={location}
      navigate={navigate}
    />
  );
}

export const pageQuery = graphql`
  fragment topDrop_ExhibitorTemplate_Bottlebooks_Event on Bottlebooks_Event {
    registeredBrands(filter: { companyId: { eq: $exhibitorId } }) {
      nodes {
        ...bb_ExhibitorPage_RegisteredBrands
      }
    }
    registeredProducts(filter: { partnerId: { eq: $exhibitorId } }) {
      ...brandCertifications_RegisteredProductConnection
    }
    registrations(filter: { companyId: { eq: $exhibitorId } }) {
      nodes {
        ...bb_Registration
        registeredProducts {
          nodes {
            ...topDrop_Price
          }
        }
      }
    }
    exhibitor(exhibitorId: $exhibitorId) {
      ...bb_ExhibitorPage
    }
  }
  query topDrop_ExhibitorTemplate(
    $id: String!
    $eventId: ID!
    $exhibitorId: ID!
    $locale: Bottlebooks_ContentLocale
    $previousId: String
    $nextId: String
  ) {
    bottlebooks {
      event(eventId: $eventId, locale: $locale) {
        ...topDrop_ExhibitorTemplate_Bottlebooks_Event
      }
    }

    exhibitor(id: { eq: $id }) {
      ...ExhibitorTemplate_Exhibitor
    }
    previous: exhibitor(id: { eq: $previousId }) {
      ...ChangeExhibitorLink
    }
    next: exhibitor(id: { eq: $nextId }) {
      ...ChangeExhibitorLink
    }
    ...topDrop_ExhibitorTemplate_Query
  }

  fragment ExhibitorTemplate_Exhibitor on Exhibitor {
    ...ExhibitorPage
    event {
      ...ExhibitorPage_Event
    }
  }

  fragment topDrop_ExhibitorTemplate_Query on Query {
    site {
      siteMetadata {
        title
        topDropCity
        topDropPriceType
      }
    }
  }
`;

function afterQuery({ bottlebooks, event, exhibitor, site }) {
  const {
    siteMetadata: { topDropCity, topDropPriceType },
  } = site;
  console.log(`Pricing for ${topDropCity} ${topDropPriceType}`);
  const bb_exhibitor = bottlebooks?.event?.exhibitor;
  const bb_registration = bottlebooks?.event?.registrations?.nodes?.[0];
  const bb_registeredBrands = bottlebooks?.event?.registeredBrands?.nodes;
  const bb_products = bb_exhibitor?.products?.nodes;

  const products = exhibitor.products?.map((product, index) => {
    if (!bb_products?.length) return product;
    const registeredProduct =
      bb_registration?.registeredProducts?.nodes?.[index];
    return {
      ...product,
      ...bb_products[index],
      ...registeredProduct?.product,
      pricing: {
        isSoldByEverythingWine: !!registeredProduct?.everythingWine?.text,
        topDropPriceType,
        ...getTopDropPricing({
          topDropPriceType,
          topDropCity,
          registeredProduct,
        }),
      },
    };
  });
  const enhancedExhibitor = {
    ...exhibitor,
    ...bb_exhibitor,
    ...bb_registration,
    ...getCertificationsForId({
      extractIdsFn: extractPartnerIdsForCharacteristics,
      id: exhibitor.exhibitorId,
      event: bottlebooks?.event,
    }),
    brands: bb_registeredBrands,
    products,
  };
  return { site, event, exhibitor: enhancedExhibitor };
}
